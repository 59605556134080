exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gis-js": () => import("./../../../src/pages/gis.js" /* webpackChunkName: "component---src-pages-gis-js" */),
  "component---src-pages-screen-reader-access-js": () => import("./../../../src/pages/screen-reader-access.js" /* webpackChunkName: "component---src-pages-screen-reader-access-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-news-and-event-details-js": () => import("./../../../src/templates/news-and-event-details.js" /* webpackChunkName: "component---src-templates-news-and-event-details-js" */),
  "component---src-templates-news-and-event-js": () => import("./../../../src/templates/news-and-event.js" /* webpackChunkName: "component---src-templates-news-and-event-js" */),
  "component---src-templates-picture-gallery-js": () => import("./../../../src/templates/picture-gallery.js" /* webpackChunkName: "component---src-templates-picture-gallery-js" */),
  "component---src-templates-procurement-details-js": () => import("./../../../src/templates/procurement-details.js" /* webpackChunkName: "component---src-templates-procurement-details-js" */),
  "component---src-templates-procurement-js": () => import("./../../../src/templates/procurement.js" /* webpackChunkName: "component---src-templates-procurement-js" */),
  "component---src-templates-programme-details-js": () => import("./../../../src/templates/programme-details.js" /* webpackChunkName: "component---src-templates-programme-details-js" */),
  "component---src-templates-programme-js": () => import("./../../../src/templates/programme.js" /* webpackChunkName: "component---src-templates-programme-js" */),
  "component---src-templates-programme-main-js": () => import("./../../../src/templates/programme-main.js" /* webpackChunkName: "component---src-templates-programme-main-js" */),
  "component---src-templates-publication-js": () => import("./../../../src/templates/publication.js" /* webpackChunkName: "component---src-templates-publication-js" */),
  "component---src-templates-recruitment-details-js": () => import("./../../../src/templates/recruitment-details.js" /* webpackChunkName: "component---src-templates-recruitment-details-js" */),
  "component---src-templates-recruitment-js": () => import("./../../../src/templates/recruitment.js" /* webpackChunkName: "component---src-templates-recruitment-js" */),
  "component---src-templates-report-details-js": () => import("./../../../src/templates/report-details.js" /* webpackChunkName: "component---src-templates-report-details-js" */),
  "component---src-templates-report-js": () => import("./../../../src/templates/report.js" /* webpackChunkName: "component---src-templates-report-js" */),
  "component---src-templates-success-story-details-js": () => import("./../../../src/templates/success-story-details.js" /* webpackChunkName: "component---src-templates-success-story-details-js" */),
  "component---src-templates-success-story-js": () => import("./../../../src/templates/success-story.js" /* webpackChunkName: "component---src-templates-success-story-js" */),
  "component---src-templates-tender-details-js": () => import("./../../../src/templates/tender-details.js" /* webpackChunkName: "component---src-templates-tender-details-js" */),
  "component---src-templates-tender-js": () => import("./../../../src/templates/tender.js" /* webpackChunkName: "component---src-templates-tender-js" */),
  "component---src-templates-training-details-js": () => import("./../../../src/templates/training-details.js" /* webpackChunkName: "component---src-templates-training-details-js" */),
  "component---src-templates-training-js": () => import("./../../../src/templates/training.js" /* webpackChunkName: "component---src-templates-training-js" */),
  "component---src-templates-training-main-js": () => import("./../../../src/templates/training-main.js" /* webpackChunkName: "component---src-templates-training-main-js" */),
  "component---src-templates-update-of-the-week-detail-js": () => import("./../../../src/templates/update-of-the-week-detail.js" /* webpackChunkName: "component---src-templates-update-of-the-week-detail-js" */),
  "component---src-templates-update-of-the-week-js": () => import("./../../../src/templates/update-of-the-week.js" /* webpackChunkName: "component---src-templates-update-of-the-week-js" */),
  "component---src-templates-video-gallery-js": () => import("./../../../src/templates/video-gallery.js" /* webpackChunkName: "component---src-templates-video-gallery-js" */)
}

